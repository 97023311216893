import React from 'react';
import {useNavigate} from 'react-router-dom';
import './../css/loginpage.css'
import NavbarHOR from './navbarhorizontal';

function Login() {
    const navigate = useNavigate(); // Obtiene la función de navegación
  
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loggedIn, setLoggedIn] = React.useState(false);
  
    const handleLogin = () => {
      // Tu lógica de inicio de sesión aquí
      if (username === 'admin' && password === 'password') {
        setLoggedIn(true);
        navigate('/salas'); // Redirige al componente Home
      } else {
        alert('Nombre de usuario o contraseña incorrectos');
      }
    };
  
    return (
    
     <div>
        <NavbarHOR/>
        <h2 id="title" class="form-text" >Iniciar sesión</h2>
        <div id="user">
          <label class="form-label form-text" htmlFor="username">Nombre de usuario: </label>
          <br />
          <input
            class="form-control"
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div id="user2">
          <label class="form-label form-text" htmlFor="password">Contraseña:</label>
          <br />
          <input
            class="form-control"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div id="user3">
          <button onClick={handleLogin} type="button" class="btn btn-secondary opacity-75">Iniciar Sesión</button>
        </div>
      </div>
    );
  }

export default Login;
