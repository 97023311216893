import React, { useState } from 'react';

const Menu = ({ toggleNavbar }) => {
  return (
    <button className="toggle-button" onClick={toggleNavbar}>
      <div className='menu'></div>
    </button>
  );
};

export default Menu;