import React, {useState} from "react";
import logopag from "./../img/balon.png"
import "./../css/navbarhorizontal.css"
import Menu from "./menu";
import { BrowserRouter as Router, Routes, Link, Route, useLocation } from 'react-router-dom';
function NavbarHOR(){
  const [isNavbarOpen, setIsNavbarOpen] = useState(true); // Estado para controlar la visibilidad de la navbar
  const location = useLocation();

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
    return (
        <div className="all">
        
        <nav class="navbar navbarhoreditar navbar-expand-lg ">
  <div class="container-fluid">
    <a class="navbar-brand itemsedit" href="#">
    Gestion Club
    <img src={logopag} alt="Logo" width="30" height="24" class="d-inline-block align-text-top"/>
    
      </a>
      
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        
        <li class="nav-item">
        
          <a class="nav-link itemsedit" aria-current="page" href="#"></a>
        </li>
        <li class="nav-item">
          <a class="nav-link itemsedit" href="#">Contactanos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link itemsedit" href="#">Politica & Privacidad </a>
        </li>
      </ul>
      <span class="navbar-text">
        
      </span>
    </div>
  </div>
</nav>
</div>
    )
}
export default NavbarHOR;