import React, {useState} from 'react';
import { Chatbot } from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css'
import config from './chatbotConfig.js'; // Asegúrate de crear este archivo
import ActionProvider  from './actionProvider.js'; // Asegúrate de crear este archivo
import MessageParser from './messageParser.js';
import './../css/chatbot.css';
const Chatbott = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div className='contBUBBLE'>
      <div
        className={`chat-bubble ${isChatOpen ? 'open' : ''}`}
        onClick={toggleChat}
      >
        <button className='bubble'></button>


      </div>
      {isChatOpen && (
        <div className="chat-container">
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            headerText="Asistente"
          />
        </div>
      )}
    </div>
    );
};

export default Chatbott;
