import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Link, Route, useLocation } from 'react-router-dom';
import './../css/navbarvertical.css'; // Importar el archivo CSS para estilos
import Chatbott from './chatbot';
import Menu from "./menu";
const Navbarvert = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(true); // Estado para controlar la visibilidad de la navbar
  const location = useLocation();

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
  const isLinkActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={`navbar navbarvert ${isNavbarOpen ? '' : 'open'}`}>
      <Menu toggleNavbar={toggleNavbar} />
    <ul className="navbar-list">
      <li>
        <Link to="/salas" id="sala" className={`button-link ${isLinkActive('/salas') ? 'active' : ''}`}>
          <div className="button-text">Dashboard</div>
        </Link>
      </li>
      <li>
        <Link to="/tutor" id="tutor" className={`button-link ${isLinkActive('/tutor') ? 'active' : ''}`}>
          <div className="button-text">Club</div>
        </Link>
      </li>
      <li>
        <Link to="/solicitar" id="solicitar" className={`button-link ${isLinkActive('/solicitar') ? 'active' : ''}`}>
          <div className="button-text">Usuario</div>
        </Link>
      </li>
    </ul>
    <Chatbott/>
  </div>

  );
};

export default Navbarvert;