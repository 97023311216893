import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import NavbarHOR from './complementos/navbarhorizontal';
import Login from './complementos/loginpage.jsx'
import Salas from "./complementos/salas.jsx";
import Solicitar from "./complementos/solicitar.jsx";
import Tutor from "./complementos/tutor.jsx";
import Navbarvert from "./complementos/navbarVertical.jsx"

function SALAS(){
  return(
    <>
    <Salas/>
    <NavbarHOR/>
    <Navbarvert/>
    
    </>
    
  ) 
}
function TUTORES(){
  return(
    <>
    <Tutor/>
    <NavbarHOR/>
    <Navbarvert/>
    </>
    
  ) 
}
function SOLICITAR(){
  return(
    <>
    <Solicitar/>
    <NavbarHOR/>
    <Navbarvert/>
    </>
    
  ) 
}
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login/>} />
        <Route path="/salas" element={<SALAS />} />
        <Route path="/tutor" element={<TUTORES />} />
        <Route path="/solicitar" element={<SOLICITAR />} />
        <Route path="/"  />
      </Routes>
      
    </Router>
  );
}

export default App;
